<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <number-value :value="molSO3" unit="\text{mol}" />
        of
        <chemical-latex content="SO3(g)" />
        was placed in a
        <number-value :value="volume" unit="\text{L}" />
        reaction vessel and underwent the reaction shown below:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 SO3(g) <=> 2 SO2(g) + O2(g)" />
      </p>

      <p class="mb-3">
        If there is
        <number-value :value="molO2" unit="\text{mol}" />
        of
        <chemical-latex content="O2(g)" />
        in the reaction vessel when the system reaches equilibrium, what is the equilibrium
        constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        for the reaction.
      </p>

      <calculation-input
        v-model="inputs.Kc"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question116',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
    molSO3() {
      return this.taskState.getValueBySymbol('molSO3').content;
    },
    molO2() {
      return this.taskState.getValueBySymbol('molO2').content;
    },
  },
};
</script>
